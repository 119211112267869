import { useMemo } from "react";
import { bscTestnet, mainnet } from "viem/chains";

/**
 * Shorten ether address
 * @param {`0x${string}` | undefined} address
 * @returns {`0x${string}`} Shortened address
 */
export const useShortenAddress = (address) => {
  const result = useMemo(() => {
    if (!address) {
      return address;
    }

    const length = address.length;

    return `${address.substring(0, 6)}...${address.substring(
      length - 4,
      length
    )}`;
  }, [address]);

  return result;
};

export const useDesiredChain = () => {
  const desiredChain = useMemo(() => {
    return process.env.REACT_APP_NETWORK !== "testnet" ? mainnet : bscTestnet;
  }, []);

  return { chain: desiredChain, chainId: desiredChain.id };
};
