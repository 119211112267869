import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";
import TuneIcon from "@mui/icons-material/Tune";
import TabItem from "../../components/tab/TabItem";
import TabDetail from "../../components/tab/TabDetail";
import TabProperty from "../../components/tab/TabProperty";
import Content from "../../components/Content";
import BuyPresale from "./BuyPresale";

const Main = () => {
  const [tabName, setTabName] = useState("detail");

  return (
    <main className="overflow-hidden">
      <div className="relative">
        <div className="absolute top-0 lg:top-[50px] left-0 z-0 lg:scale-125 scale-150 opacity-50">
          <svg
            width="1879"
            height="2159"
            viewBox="0 0 1879 2159"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="text-4xl"
          >
            <path
              d="M155 6C864.167 77 2186.9 270.9 1804.5 478.5C1326.5 738 -1.99991 742 7.00009 1138C16.0001 1534 694 1980 1772.5 2153"
              stroke="#6BFF0B"
              strokeWidth="12"
            />
          </svg>
        </div>
      </div>

      <div className="py-[10px] md:py-[50px] lg:py-[100px] xl:w-main-content px-6 sm:px-0 lg:w-[80%] sm:w-[90%] w-full mx-auto relative z-30">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-14 gap-y-5">
          <div className="block">
            <img src="coinfest.7c3abbd9.gif" className="w-full" alt="" />
          </div>

          <div className="w-full">
            <h1 className="mb-2 md:mb-3 lg:mb-4 text-[40px] md:text-[50px] lg:text-[60px] text-primary font-bold">
              SLEEK SALE
            </h1>
            <p className="mb-1 text-lg lg:text-xl md:mb-4 lg:mb-5">
              After 3 Years Of Reseach In Space Laborators. Moonfall Is In Its
              Final Stages Before Launch! Only Users Registered With The Same
              Funds Can Participate In MFL Sale
            </p>
            <div className="flex items-center mt-4 mb-2 gap-x-1 md:mb-3 md:gap-x-2">
              <Tooltip title="Verified Collection" placement="top">
                <CheckCircleIcon className="text-[#14B883] w-[15px] md:w-[30px]" />
              </Tooltip>
              <span className="text-base md:text-[17px] font-semibold uppercase">
                LIVE - SEED
              </span>
            </div>
            <div className="px-2 py-3 border rounded-lg md:px-4 md:py-5 lg:px-4 lg:py-6 border-slate-600 bg-stroke">
              <Content title="Token:" content="MFL"></Content>
              <Content title="AVAILABLE TOKEN" content="NAN"></Content>
              <Content title="TOKEN FOR SALE:" content="100.00M"></Content>
              <Content
                title="PRICE PER TOKEN:"
                content="0,000025ETH"
                haveBorder={false}
              ></Content>
            </div>
            <BuyPresale />
          </div>
        </div>
        <div className="mt-5 md:mt-5 lg:mt-10">
          <div className="flex items-center ">
            <TabItem
              title="Detail"
              active={tabName === "detail"}
              onClick={() => setTabName("detail")}
            >
              <ListAltIcon className="text-2xl lg:text-3xl" />
            </TabItem>
            <TabItem
              title="Properties"
              active={tabName === "property"}
              onClick={() => setTabName("property")}
            >
              <TuneIcon className="text-2xl lg:text-3xl" />
            </TabItem>
          </div>
          <div className="px-2 py-3 border rounded-b-lg rounded-tr-lg lg:w-[95%] md:px-3 md:py-4 lg:px-4 lg:py-6 border-slate-600 bg-stroke">
            {tabName === "detail" ? <TabDetail /> : <TabProperty />}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Main;
