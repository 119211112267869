import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { dappConfig } from './dapp.config';

const queryClient = new QueryClient();

const DappProvider = ({ children }) => {
  return (
    <WagmiProvider config={dappConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
};

export default DappProvider;
