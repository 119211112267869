import BigNumber from "bignumber.js";
import { bscTestnet, mainnet } from "viem/chains";

export const REQUIRE_CONNECT_TEXT = "Please connect wallet to continue";

export const CONTRACTS = {
  [bscTestnet.id]: "0xdbd93872793a3647b40cd56c11fc3d5d4d03f3ab",
  [mainnet.id]: "0x1edf9943ebacbaa325dccf677bc3b3da2cd1a2ab",
};

export const TOKEN_DIVIDEND = BigNumber(10).pow(18);
