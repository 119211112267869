import React from "react";
import DetailItem from "../DetailItem";

const TabDetail = () => {
  return (
    <div className="flex flex-col gap-y-3 md:gap-y-4">
      <DetailItem title="Current Funding Round:" content="Seed" />
      <DetailItem title="Price Per Token:" content="1MFL = 0,05$" />
      <DetailItem
        title="Token Release Schedule:"
        content="5%TGE, Balance Unlocks Over 9 Months"
      />
      <DetailItem title="Specified Currency When Purchasing:" content="ETH" />
      <DetailItem title="Swap Rate:" content="1ETH = 39.040 MFL" />
      <DetailItem title="TGE Expectesd:" content="Jun 2024" />
    </div>
  );
};

export default TabDetail;
