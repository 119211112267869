import { Helmet as ReactHelmet } from 'react-helmet';

const Helmet = () => {
  return (
    <ReactHelmet>
      <title>Sleek</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content="Helmet application" />
    </ReactHelmet>
  );
};

export default Helmet;
