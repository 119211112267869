/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import ButtonPrimary from "../../components/ButtonPrimary";
import AlertDialog from "../../components/AlertDialog";
import { CircularProgress } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import {
  useAccount,
  useBalance,
  useEstimateGas,
  useEstimateMaxPriorityFeePerGas,
  useGasPrice,
  useReadContracts,
  useWaitForTransactionReceipt,
  useWriteContract,
  // useReadContracts,
} from "wagmi";
import { sleekAbi } from "../../common/abi/sleekAbi";
import { parseEther } from "viem";
import {
  CONTRACTS,
  REQUIRE_CONNECT_TEXT,
  TOKEN_DIVIDEND,
} from "../../common/constants";
import BigNumber from "bignumber.js";
import { useDesiredChain } from "../../common/hooks";

const BuyPresale = () => {
  const { chain: desiredChain } = useDesiredChain();
  const [openAlert, setOpenAlert] = useState(false);

  const [joinedPool, setJoinedPool] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);

  const { address, isConnected, isDisconnected, chainId } = useAccount();

  const balance = useBalance({
    address,
  });

  const { data: hash, error, isPending, writeContract } = useWriteContract();

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  const { refetch: refetchWhitelist } = useReadContracts({
    allowFailure: true,
    contracts: [
      {
        address: CONTRACTS[desiredChain.id],
        abi: sleekAbi,
        functionName: "whitelist",
        args: [address],
      },
    ],
    query: {
      enabled: false,
    },
  });

  const estimateGasForMax = useEstimateGas();
  const estimateMaxPriorityFeePerGasForMax = useEstimateMaxPriorityFeePerGas();
  const gasPrice = useGasPrice();

  const isOpenModal = (isPending || isConfirming) && !isConfirmed && !error;

  const isBuyDisabled =
    isPending || isConfirming || desiredChain.id !== chainId || !isConnected;

  const onClickMax = useCallback(() => {
    const balanceValue = balance?.data?.value;

    if (!balanceValue) {
      return;
    }

    const balanceValueBn = BigNumber(balanceValue);
    const estimateGasValue = estimateGasForMax?.data;
    const gasPriceValue = gasPrice?.data;

    if (!estimateGasForMax.error && estimateGasValue && gasPriceValue) {
      const totalEstimateGasFee =
        BigNumber(estimateGasValue).multipliedBy(gasPriceValue);

      const totalEstimateFee = totalEstimateGasFee.plus(
        totalEstimateGasFee.multipliedBy(0.1)
      ); // add extra 10%

      const maxValue = balanceValueBn
        .minus(totalEstimateFee)
        .div(TOKEN_DIVIDEND);

      setInputValue(maxValue.toString());
    } else {
      setInputValue(balanceValueBn.div(TOKEN_DIVIDEND));
    }
  }, [balance, estimateGasForMax, estimateMaxPriorityFeePerGasForMax]);

  const onCheckWhiteList = useCallback(async () => {
    const whiteListQuery = await refetchWhitelist();
    const whiteListResult = whiteListQuery.data?.[0];

    if (
      whiteListQuery.error ||
      !whiteListResult ||
      whiteListResult.error ||
      typeof whiteListResult.result === "undefined" ||
      whiteListResult.status !== "success"
    ) {
      setOpenAlert(true);
      setErrorMsg("An error occurred. Please try again.");
      return;
    }

    if (!whiteListResult.result) {
      setOpenAlert(true);
      setErrorMsg("Sorry, your wallet is not whitelisted");
      return;
    }

    setJoinedPool(true);
  }, []);

  const onClickBuy = useCallback(async () => {
    setErrorMsg(null);

    let parsedValue;

    if (inputValue?.length <= 0) {
      setErrorMsg("Please enter a valid value.");
      return;
    }

    parsedValue = parseEther(inputValue);

    if (parsedValue <= 0) {
      setErrorMsg("Please enter a valid value.");
      return;
    }

    if (parsedValue > balance.data.value) {
      setErrorMsg("Insufficient balance.");
      return;
    }

    writeContract({
      address: CONTRACTS[desiredChain.id],
      abi: sleekAbi,
      functionName: "buyPresale",
      value: parsedValue,
    });
  }, [inputValue]);

  const onChangeInputValue = useCallback((event) => {
    setInputValue(event.target.value);
  }, []);

  const onWheel = useCallback(() => {
    if (
      typeof document !== "undefined" &&
      document.activeElement.type === "number"
    ) {
      document.activeElement.blur();
    }
  }, []);

  useEffect(() => {
    if (isDisconnected) {
      setJoinedPool(false);
    }
  }, [isConnected]);

  useEffect(() => {
    if (error) {
      setErrorMsg(error.shortMessage);
    } else {
      setErrorMsg(null);
    }
  }, [error]);

  return joinedPool ? (
    <>
      <div className="flex flex-col px-4 py-6 mt-5 border rounded-lg gap-y-4 border-slate-600 bg-stroke">
        <div>
          <div className="flex">
            <input
              type="number"
              className="w-full px-3 py-4 text-white transition-all rounded-s-lg bg-slate-700 focus:bg-slate-600"
              value={inputValue}
              onWheel={onWheel}
              onChange={onChangeInputValue}
              placeholder="Enter ETH Amount"
              disabled={isBuyDisabled}
            />

            <ButtonPrimary
              className="w-1/4 rounded-l-none rounded-r-lg"
              onClick={onClickMax}
              disabled={isBuyDisabled}
            >
              Max
            </ButtonPrimary>
          </div>

          {isConfirmed && hash && (
            <div className="mt-2 overflow-x-hidden text-primary text-ellipsis">
              Transction confirmed:{" "}
              <a
                className="underline"
                href={
                  new URL(`tx/${hash}`, desiredChain.blockExplorers.default.url)
                }
                target="_blank"
                rel="noreferrer"
              >
                {hash}
              </a>
            </div>
          )}

          {errorMsg && <div className="mt-2 text-red-400">{errorMsg}</div>}
        </div>

        <ButtonPrimary
          className="w-full px-3 py-[10px]"
          onClick={onClickBuy}
          disabled={isBuyDisabled}
        >
          {isConnected ? "Buy" : REQUIRE_CONNECT_TEXT}
        </ButtonPrimary>
      </div>

      <AlertDialog open={isOpenModal} onClose={null}>
        <CircularProgress className="text-primary" size="4rem" thickness={6} />
        <span className="text-2xl text-center">
          {isPending && "Pending transaction..."}
          {isConfirming && "Confirming transaction..."}
          {isConfirmed && "Transaction confirmed!"}
        </span>
      </AlertDialog>
    </>
  ) : (
    <>
      <ButtonPrimary
        className="w-full px-3 py-[10px] mt-8"
        onClick={onCheckWhiteList}
        disabled={isBuyDisabled}
      >
        {isConnected ? "Join Pool" : REQUIRE_CONNECT_TEXT}
      </ButtonPrimary>
      <AlertDialog open={openAlert} onClose={() => setOpenAlert(false)}>
        <BlockIcon sx={{ width: "6rem", height: "auto" }} />
        <span className="text-2xl text-center">{errorMsg}</span>
      </AlertDialog>
    </>
  );
};

export default BuyPresale;
