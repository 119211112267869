/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from "react";
import ButtonPrimary from "../components/ButtonPrimary";
import { useAccount, useConnect, useSwitchChain } from "wagmi";
import { useEffect } from "react";
import { CircularProgress, Tooltip } from "@mui/material";

import DangerousIcon from "@mui/icons-material/Dangerous";
import AlertDialog from "./AlertDialog";
import { useDesiredChain, useShortenAddress } from "../common/hooks";

const className = "min-w-[140px] px-3 py-2 ml-0 lg:ml-5";
const copyTextInitial = "Click to copy";
const copyTextCopied = "Copied to clipboard!";

const ConnectWallet = () => {
  const { chain: chainToConnect, chainId: chainIdToConnect } =
    useDesiredChain();

  const [openAlert, setOpenAlert] = useState(false);
  const [tooltipText, setTooltipText] = useState(copyTextInitial);

  const { connect, connectors } = useConnect();
  const { address, isConnecting, isReconnecting, isConnected, chainId } =
    useAccount();
  const shortenedAddress = useShortenAddress(address);
  const { switchChain } = useSwitchChain();

  const isLoading = isConnecting || isReconnecting;

  useEffect(() => {
    if (!address) {
      setOpenAlert(false);
      return;
    }

    if (chainId !== chainIdToConnect) {
      setOpenAlert(true);
      switchChain({ chainId: chainIdToConnect });
    } else {
      setOpenAlert(false);
    }
  }, [chainId, address]);

  useEffect(() => {
    if (tooltipText === copyTextCopied) {
      const timeout = setTimeout(() => {
        setTooltipText(copyTextInitial);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [tooltipText]);

  const copyAddress = useCallback(async () => {
    if (address) {
      await navigator.clipboard.writeText(address);
      setTooltipText(copyTextCopied);
    }
  }, [address]);

  return (
    <>
      {!address && !isConnected && (
        <ButtonPrimary
          className={`${className} ${
            isLoading && "bg-opacity-70 h-11 pointer-events-none"
          } `}
          onClick={() =>
            connect({ chainId: chainIdToConnect, connector: connectors[0] })
          }
        >
          {isLoading ? (
            <CircularProgress
              size="1.5rem"
              thickness={6}
              sx={{ color: "black" }}
            />
          ) : (
            "Connect Wallet"
          )}
        </ButtonPrimary>
      )}

      {address && (
        <Tooltip title={tooltipText} sx={{ fontSize: 14 }}>
          <div>
            <ButtonPrimary className={className} onClick={copyAddress}>
              {shortenedAddress}
            </ButtonPrimary>
          </div>
        </Tooltip>
      )}

      <AlertDialog open={openAlert} onClose={null}>
        <DangerousIcon sx={{ width: "6rem", height: "auto" }} />
        <span className="text-2xl text-center">
          Please switch to{" "}
          <span className="font-bold">{chainToConnect.name}</span>
          <br />
          to continue
        </span>
      </AlertDialog>
    </>
  );
};

export default ConnectWallet;
