import React from "react";

const Content = ({ title = "", content = "", haveBorder = true }) => {
  return (
    <div
      className={`flex items-center justify-between p-2 md:px-4 lg:py-4 text-base lg:text-lg ${
        haveBorder && "border-b border-slate-600"
      }`}
    >
      <span>{title}</span>
      <span className="text-primary">{content}</span>
    </div>
  );
};

export default Content;
