import React from "react";

const ButtonPrimary = ({
  children,
  onClick = () => {},
  className = "",
  type = "button",
  disabled,
}) => {
  return (
    <button
      className={
        "flex justify-center items-center bg-secondary font-bold lg:text-lg text-base rounded-md text-black hover:bg-primary disabled:bg-gray-400 " +
        className
      }
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default ButtonPrimary;
