import React from "react";

const TabItem = ({
  children,
  title = "",
  active = false,
  onClick = () => {},
}) => {
  return (
    <div
      className={`flex items-center px-6 py-3 cursor-pointer gap-x-[6px] transition-all hover:text-white select-none ${
        active ? "border-b-[3px] border-secondary text-white" : "text-text-1"
      }`}
      onClick={onClick}
    >
      {children}
      <h1 className="text-xl font-semibold md:text-2xl">{title}</h1>
    </div>
  );
};

export default TabItem;
