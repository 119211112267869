import React from "react";

const DetailItem = ({ title = "", content = "" }) => {
  return (
    <div className="flex items-center text-base gap-x-5 lg:text-lg">
      <span className="flex-[1] text-text-1">{title}</span>
      <span className="flex-[2] text-primary">{content}</span>
    </div>
  );
};

export default DetailItem;
