import React from "react";

const listSrc = [
  "0.png",
  "1.png",
  "2.png",
  "3.png",
  "4.png",
  "5.png",
  "6.png",
  "7.png",
];

const TabProperty = () => {
  return (
    <div className="grid w-full grid-cols-3 gap-3 lg:grid-cols-5 sm:grid-cols-4">
      {listSrc.map((src) => (
        <div className="flex items-center justify-center p-2">
          <img className="w-full sm:w-[80%] lg:w-[60%]" src={src} alt="" />
        </div>
      ))}
    </div>
  );
};

export default TabProperty;
