import "./App.css";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import DappProvider from "./common/provider/DappProvider";
import Helmet from "./layout/Helmet";
import Main from "./layout/main";

function App() {
  return (
    <>
      <Helmet />
      <DappProvider>
        <Header />
        <Main />
        <Footer />
      </DappProvider>
    </>
  );
}

export default App;
