import { http, createConfig } from 'wagmi';
import { bscTestnet, mainnet } from 'wagmi/chains';
import { injected } from 'wagmi/connectors';

export const dappConfig = createConfig({
  chains: [mainnet, bscTestnet],
  connectors: [injected()],
  transports: {
    [mainnet.id]: http(),
    [bscTestnet.id]: http(),
  },
});
