import React from "react";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import IconSocial from "../components/IconSocial";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import ConnectWallet from "../components/ConnectWallet";

const Header = () => {
  return (
    <header className="sticky top-0 left-0 z-50 backdrop-blur-md">
      <div className="w-full py-4 mx-auto px-6 lg:px-0 lg:py-4 xl:w-main-content lg:w-[80%]">
        <div className="flex items-center justify-between">
          <a href="/">
            <img
              src="sleek-logo.webp"
              alt=""
              className="w-[80px] md:w-[90px] lg:w-[110px]"
            />
          </a>

          <div className="flex items-center justify-end gap-x-3">
            <IconSocial href="https://t.me/+ezem7DnWr6k3Njhl">
              <TelegramIcon className="hidden md:block md:text-2xl lg:text-3xl" />
            </IconSocial>
            <IconSocial href="https://twitter.com/sleekcard">
              <TwitterIcon className="hidden md:block md:text-2xl lg:text-3xl" />
            </IconSocial>
            <IconSocial href="https://www.instagram.com/sleekcard/">
              <InstagramIcon className="hidden md:block md:text-2xl lg:text-3xl" />
            </IconSocial>
            <IconSocial href="https://www.linkedin.com/company/sleekcard/">
              <LinkedInIcon className="hidden md:block md:text-2xl lg:text-3xl" />
            </IconSocial>
            <ConnectWallet />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
