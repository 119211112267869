import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const AlertDialog = ({ open, onClose, children }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown
    >
      <DialogContent className="bg-stroke" sx={{ py: '3rem' }}>
        <DialogContentText
          className="text-primary"
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={4}
        >
          {children}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default AlertDialog;
